import { jwtDecode } from "jwt-decode";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from '../../contexts/authContext';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import { MdOutlineLanguage } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";

const OTPForm = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { setUser, setToken , user, token} = useContext(AuthContext);
  const [mobile, setMobile] = useState(state?.mobile);
  const inputsRef = useRef([]);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [countdown, setCountdown] = useState(60); // Countdown state
  const [canResend, setCanResend] = useState(false); // Resend button state
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const { t, i18n } = useTranslation();

  // console.log(token, 'User Logged In');

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);
  
  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen(!languageDropdownOpen);
  };
  useEffect(() => {
    // Timer for countdown
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setCanResend(true); // Enable resend button when countdown is over
    }
  }, [countdown]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        !/^[0-9]{1}$/.test(e.key) &&
        e.key !== "Backspace" &&
        e.key !== "Delete" &&
        e.key !== "Tab" &&
        !e.metaKey
      ) {
        e.preventDefault();
      }

      if (e.key === "Delete" || e.key === "Backspace") {
        const index = inputsRef.current.indexOf(e.target);
        if (index > 0) {
          inputsRef.current[index - 1].value = "";
          inputsRef.current[index - 1].focus();
        }
      }
    };

    const handleInput = (e) => {
      const target = e.target;
      const index = inputsRef.current.indexOf(target);
      if (target.value) {
        if (index < inputsRef.current.length - 1) {
          inputsRef.current[index + 1].focus();
        } else {
          document.getElementById("submit-btn").focus();
        }
      }
      const otpValue = inputsRef.current.map((input) => input.value).join("");
      setOtp(otpValue);
    };

    const handleFocus = (e) => {
      e.target.select();
    };

    const handlePaste = (e) => {
      e.preventDefault();
      const text = e.clipboardData.getData("text");
      if (!new RegExp(`^[0-9]{${inputsRef.current.length}}$`).test(text)) {
        return;
      }
      const digits = text.split("");
      inputsRef.current.forEach(
        (input, index) => (input.value = digits[index])
      );
      document.getElementById("submit-btn").focus();
      setOtp(text);
    };

    inputsRef.current.forEach((input) => {
      input.addEventListener("input", handleInput);
      input.addEventListener("keydown", handleKeyDown);
      input.addEventListener("focus", handleFocus);
      input.addEventListener("paste", handlePaste);
    });

    return () => {
      inputsRef.current.forEach((input) => {
        if (input) {
          input.removeEventListener("input", handleInput);
          input.removeEventListener("keydown", handleKeyDown);
          input.removeEventListener("focus", handleFocus);
          input.removeEventListener("paste", handlePaste);
        }
      });
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/auth/verifyOTP`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ mobile: `+251${mobile}`, otp }),
        }
      );
      if (response.status === 401) {
        navigate("/signup", {
          state: {
            mobile,
          },
        });
        return;
      }
      const data = await response.json();
      // console.log("token", data);
      const decodedToken = jwtDecode(data);
      if (decodedToken) {
        //console.log("user after ", decodedToken?.data.firstname);
        setUser(decodedToken);
        setToken(data);
        localStorage.setItem("token", data);
        navigate("/");
      }
      if (response.ok) {
        console.log("Verification successful");
      } else {
        setError("Verification failed: " + data.message);
      }
    } catch (error) {
      console.error("An error occurred: " + error.message);
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleResend = async () => {
    setError(""); // Clear any previous error
    setLoading(true); // Optional: Show loading state for sending OTP
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/auth/sendOTP`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ mobile: `+251${mobile}` }), // Adjusted phone number format
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send verification code");
      }

      // If successful, reset countdown and update button state
      // console.log("OTP sent successfully");
      setCountdown(60); // Reset countdown
      setCanResend(false); // Disable resend button until countdown ends
    } catch (error) {
      console.error("An error occurred while resending OTP: " + error.message);
      setError("An error occurred while resending the OTP. Please try again.");
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <>
      <header className="flex justify-between align-middle p-2 ">
        <div className="">
          {/* Menu Toggle Button (Mobile) */}

          {/* Logo */}
          <div className="ml-4 flex items-center">
            <Link to="/">
              <img
                loading="lazy"
                src="./qetero_logo_text_3.svg"
                className="w-18 h-10 "
                alt="DARS Logo"
              />
            </Link>
          </div>
        </div>
        <div className="p-2">
        <button
          onClick={toggleLanguageDropdown}
          className="flex items-center px-2 py-1 rounded-full bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
        >
          <MdOutlineLanguage className="text-white w-4 h-4 mr-2" />
          <span className="text-white text-xs md:text-sm font-medium">
            {i18n.language === "en" ? "English" : "አማርኛ"}
          </span>
          <IoIosArrowDown className="text-white w-4 h-4 mr-2" />
        </button>

        {languageDropdownOpen && (
          <div className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <button
                onClick={() => {
                  i18n.changeLanguage("en");
                  setLanguageDropdownOpen(false);
                }}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                English
              </button>
              <button
                onClick={() => {
                  i18n.changeLanguage("am");
                  setLanguageDropdownOpen(false);
                }}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                አማርኛ
              </button>
            </div>
          </div>
        )}
      </div>
      </header>

      <div className="relative min-h-screen flex flex-col justify-center bg-slate-50 overflow-hidden">
        <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-24">
          {/* <div className=" mb-8 flex ">
            <img
              loading="lazy"
              src="./qetero_logo_text_3.svg"
              className="w-full h-12 self-center"
              alt="DARS Logo"
            />
          </div> */}
          <div className="flex justify-center">
            <div className="max-w-md mx-auto text-center bg-white px-4 sm:px-8 py-10 rounded-xl shadow-md">
              <header className="mb-8">
                <h1 className="text-2xl font-bold mb-1">
                  {t("verifyPhoneNumber")}
                </h1>
                <p className="text-[15px] text-slate-500">
                  {t("enterOtpOfFiveDigit")}
                  {mobile}.
                </p>
              </header>
              <form id="otp-form" onSubmit={handleSubmit}>
                <div className="flex items-center justify-center gap-3">
                  {Array.from({ length: 5 }).map((_, index) => (
                    <input
                      key={index}
                      type="text"
                      className="w-14 h-14 text-center text-2xl font-extrabold text-slate-900 border-primary border hover:border-slate-200 appearance-none rounded p-4 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
                      pattern="\d*"
                      maxLength="1"
                      ref={(el) => (inputsRef.current[index] = el)}
                    />
                  ))}
                </div>
                {error && <p className="text-red-500 text-sm mt-2">Your Verification Code is Expired or Invalid</p>}
                <div className="max-w-[260px] mx-auto mt-4">
                  <button
                    id="submit-btn"
                    type="submit"
                    className="text-white w-full mt-12 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    disabled={loading}
                  >
                    {loading ? `${t("verifying")}...` : `${t("verifyAccount")}`}
                  </button>
                </div>
              </form>
              <div className="text-sm text-slate-500 mt-4">
                {canResend ? (
                  <button
                    onClick={handleResend}
                    className="font-medium text-blue-500 hover:text-blue-600"
                  >
                    {t("resendAgain")}
                  </button>
                ) : (
                  <span>
                    {t("didntReceivePin")}{" "}
                    <span className="font-medium text-blue-500">
                      {t("resendIn")} {countdown} {t("seconds")}
                    </span>
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OTPForm;
