export const convertToEthiopianDate = (date) => {
  const amharicDays = ['እሁድ', 'ሰኞ', 'ማክሰኞ', 'ረቡዕ', 'ሐሙስ', 'አርብ', 'ቅዳሜ'];
  const amharicMonths = ['መስከረም', 'ጥቅምት', 'ህዳር', 'ታህሳስ', 'ጥር', 'የካቲት', 'መጋቢት', 'ሚያዝያ', 'ግንቦት', 'ሰኔ', 'ሐምሌ', 'ነሐሴ', 'ጳጉሜን'];

  const gregorianDate = new Date(date);

  const ethiopianEpoch = new Date(Date.UTC(1971, 8, 12));

  const diffMilliseconds = gregorianDate.getTime() - ethiopianEpoch.getTime();
  const daysSinceEpoch = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));

  let ethiopianYear = 1963;
  let daysRemaining = daysSinceEpoch;

  while (daysRemaining > 0) {
    ethiopianYear++;
    const isLeapYear = ethiopianYear % 4 === 3;
    const yearLength = isLeapYear ? 366 : 365;
    if (daysRemaining >= yearLength) {
      daysRemaining -= yearLength;
    } else {
      break;
    }
  }

  let monthsLength = [30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 5];
  if (ethiopianYear % 4 === 3) {
    monthsLength[12] = 6;
  }

  let ethiopianMonth = 0;

  while (daysRemaining >= monthsLength[ethiopianMonth]) {
    daysRemaining -= monthsLength[ethiopianMonth];
    ethiopianMonth++;
  }

  const ethiopianDay = daysRemaining + 2;

  // **Corrected Day of the Week Calculation:**
  const ethiopianDayOfWeek = (daysSinceEpoch + 1) % 7;  // Epoch was a Wednesday (3)

  const amharicDayName = amharicDays[ethiopianDayOfWeek];
  const amharicMonthName = amharicMonths[ethiopianMonth];

  return `${amharicDayName}, ${amharicMonthName} ${ethiopianDay}, ${ethiopianYear}`;
};


export const formatAppointmentTime = (appointmentTime) => {
  // Create a new Date object from the provided appointment time
  const date = new Date(appointmentTime);
  
  // Deduct 6 hours
  date.setHours(date.getHours() - 6);  
  // write a code  it like this Oct,Tuesday, 12/2024 
  // Return formatted string
  return `${conver(date)}`;
};
export const formatAppointmentTimeAmharic = (appointmentTime) => {
  // Create a new Date object from the provided appointment time
  const date = new Date(appointmentTime);
  
  // Deduct 6 hours
  date.setHours(date.getHours() - 6);  
  // write a code  it like this Oct,Tuesday, 12/2024 
  // Return formatted string
  return `${convertToEthiopianDate(date)}`;
};

export const formatLocalTime = (appointmentTime) => {
  // Create a new Date object from the provided appointment time
  const date = new Date(appointmentTime);
  // Deduct 6 hours
  date.setHours(date.getHours() - 6);
  
  // Get the date parts
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Return formatted string
  return convertToAmPm(`${hours}:${minutes}`);
};

export const conver = (date) => {
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
    'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
  ];
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayName = days[date.getDay()];
  const monthName = months[date.getMonth()];
  const year = date.getFullYear();
  const day = String(date.getDate()).padStart(2, '0');
  return `${monthName}, ${dayName}, ${day}/${year}`;
};



// Function to convert 24-hour format to Ethiopian local time
export const convertToEthiopianLocalTime = (time24) => {
  const [hour, minute, second] = time24.split(':').map(Number);

  // Convert Gregorian hour to Ethiopian hour by adjusting by 6 hours
  let ethiopianHour = (hour + 6) % 12;
  ethiopianHour = ethiopianHour === 0 ? 12 : ethiopianHour; // Handle 12-hour wrap-around
  // console.log(ethiopianHour)
  // Determine if it's morning or afternoon in Ethiopian time
  const ethiopianPeriod = (ethiopianHour <= 6) ? 'ጥዋት':'ከሰዓት';
  return `${String(ethiopianHour).padStart(2, '0')}:${String(minute).padStart(2, '0')} ${ethiopianPeriod}`;
};

// Function to convert 24-hour format to 12-hour AM/PM format
export const convertToAmPm = (time24) => {
  const [hour, minute, second] = time24.split(':').map(Number);

  // Determine the period (AM/PM)
  const period = hour >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  let hour12 = hour % 12;
  hour12 = hour12 === 0 ? 12 : hour12; // Handle 12-hour wrap-around

  return `${String(hour12).padStart(2, '0')}:${String(minute).padStart(2, '0')} ${period}`;
};

export function formatToTimeOnly(timestamp) {
  const date = new Date(timestamp);
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const amPm = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  hours = hours % 12 || 12; // Convert 0 to 12 for midnight and handle 12-hour format
  hours = hours.toString().padStart(2, '0');

  return `${hours}:${minutes} ${amPm}`;
}


export const formatTime = (appointmentTime) => {
  const date = new Date(appointmentTime);

  // Days of the week in English
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayName = days[date.getDay()]; // Get the day name

  // Months of the year in English
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
    'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
  ];
  const monthName = months[date.getMonth()]; // Get the month name

  // Get the date parts
  const year = date.getFullYear();
  const day = String(date.getDate()).padStart(2, '0'); // Ensure day is two digits

  // Return formatted time
  return `${dayName}, ${monthName} ${day}, ${year}`;
};



