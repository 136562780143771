import React, { useState } from "react";
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { FaBuildingColumns  } from "react-icons/fa6";


const fetchBranches = async (selectedOrganization) => {
  try {
    const url = selectedOrganization
      ? `${process.env.REACT_APP_API_URL}/v1/branches/by-organization?organization_id=${selectedOrganization}`
      : `${process.env.REACT_APP_API_URL}/v1/branches`; // Use different URL if no organization is selected

    const response = await axios.get(url);
    if (response.data) {
      return response.data.data.map((branch) => ({
        id: branch.id,
        name: branch.name,
        address: branch?.location?.name,
        active: branch.active,
      }));
    }
    return []; // Return an empty array if data is not present
  } catch (error) {
    console.error("Error fetching branches:", error); // Log the error for debugging
    throw error; // Re-throw the error to be handled by the caller
  }
};


const BranchSelection = ({ selectedOrganization,selectedBranch, setSelectedBranch, setSelectedBranchName, nextStep,setSelectedAddress }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [showAll, setShowAll] = useState(false);
  
  const {
    data: locationOptions,
    isLoading: branchesLoading,
    error: branchesError,
  } = useQuery({
    queryKey: ['branches', selectedOrganization],
    queryFn:()=>fetchBranches(selectedOrganization),
    enabled: !!selectedOrganization || selectedOrganization === null, // Fetch if selectedOrganization is defined or null explicitly
  });

  // Filter and sort branches by active status
  const filteredBranches = locationOptions
    ?.filter(branch => branch.name.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => b.active - a.active);  // Sort active branches first
  console.log("branches", filteredBranches)
  const displayedBranches = showAll ? filteredBranches : filteredBranches?.slice(0, 17);

  const handleBranchSelection = (branchId, branchName, active,location) => {
    if (active) {
      setSelectedBranch(branchId);
      setSelectedBranchName(branchName);  // Set the branch name in the parent
      setSelectedAddress(location)
      // console.log('Selected Branch Name:', branchName, location);
      nextStep();
    }
  };

  return (
    <div  >
      <div className="flex flex-col justify-between bg-gray-100 p-2 container w-screen">
      <div className="border rounded-xl  bg-white border-gray-200  p-2">
      <h2 className="text-pretty text-sm md:text-lg pb-2 text-gray-600 font-semibold ">{t("selectBranch")}</h2>

        {branchesLoading ? (
          <div className="grid grid-cols-3 bg-white md:grid-cols-4 lg:grid-cols-6 gap-4">
            {              Array.from({ length: 12 }).map((_, index) => (
                <div
                  key={index}
                  className="p-4 flex flex-col items-center justify-center border-2 border-gray-300 bg-white rounded-lg shadow-sm animate-pulse"
                >
                  <div className="h-6 w-20 bg-gray-200 rounded-md mb-2"></div>
                  <div className="h-4 w-16 bg-gray-200 rounded-md"></div>
                </div>
              ))}
          </div>
        ) : branchesError ? (
          <p className="text-red-500 font-semibold text-center">Error loading branches</p>
        ) : (
          <div className="grid  grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
            {displayedBranches?.map((branch) => (
              <div
                key={branch.id}
                className={`p-2 flex flex-col md:p-2 border rounded-lg shadow-md transition-all transform hover:scale-105  
                ${branch.active ? ' bg-blue-300/5 hover:bg-blue-200 border cursor-pointer' : 'bg-gray-100 text-gray-500 opacity-50 cursor-not-allowed'} 
                ${selectedBranch === branch.id && branch.active ? 'border-blue-600' : 'border-gray-300'}`}
                onClick={() => handleBranchSelection(branch.id, branch.name, branch.active,branch?.address)}
              >
                <FaBuildingColumns  className="w-14 h-14 self-center  text-blue-800 " />
                <div className="items-center space-y-2">
                  <h3 className={`text-sm md:text-lg mt-2 line-clamp-1 text-gray-700 font-semibold text-center`}>
                    {t(branch.name)}
                  </h3>
                  {/* <h4 className="text-sm font-normal text-gray-700 text-center line-clamp-1">
                    {t(branch.address)}
                  </h4> */}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      </div>
    </div>
  );
};

export default BranchSelection;