import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiMenuAltLeft } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { FaTimes } from "react-icons/fa";
import { IoIosArrowDown, IoMdHelpCircleOutline } from "react-icons/io";
import { MdOutlineLanguage } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/authContext";
import "../../index.css";
import { jwtDecode } from "jwt-decode";

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { user, setUser, setToken } = useContext(AuthContext);
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);

  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen(!languageDropdownOpen);
  };



  const linkClasses = (path) => {
    const isActive = location.pathname === path;
    return isActive
      ? "text-pink-700 font-bold text-base my-2"
      : " font-bold text-base my-2 transition-colors duration-200 ease-in-out";
  };
  const linkClassesMobile = (path) => {
    const isActive = location.pathname === path;
    return isActive
      ? "flex justify-start bg-secondary rounded-md px-4 py-2 text-xl font-bold text-white font-bold text-base my-2"
      : "flex justify-start bg-primary rounded-md px-4 py-2 text-xl font-bold text-white font-bold text-base my-2";
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setUser(null);
    setToken(null);
    navigate("/Login");
  };

  const handleNavigation = (path) => {
    const token = localStorage.getItem("token");
    if (path === "/appointment" && !token) {
      navigate("/Login");
    } else {
      navigate(path);
    }
    setMenuOpen(false);
  };

  return (
    <header className="top-0 align-middle  left-0 w-full bg-gray-100 z-50 mb-4">
      <div className="flex justify-between items-center  mx-auto px-4 py-2">
        {/* Menu Toggle Button (Mobile) */}

        {/* Logo */}
        <div className="ml-4 flex items-center" >
          <Link to="/" >
          <img
            loading="lazy"
            src="./qetero_logo_text_3.svg"
            className="w-18 h-10 "
            alt="DARS Logo"
          /> 
          </Link>

        </div>

        {/* Space filler */}
        <div className="flex-grow"></div>

        {/* Wrapper for Desktop Menu and Language Selector */}
        <div className="flex items-center space-x-4">
          {/* Desktop Menu */}
          <nav className="hidden md:flex text-primary items-center space-x-4">
            <button onClick={() => handleNavigation("/")} className={linkClasses("/")}>
              {t("home")}
            </button>
            {/* <button onClick={() => handleNavigation("/aboutus")} className={linkClasses("/aboutus")}>
              {t("aboutUs")}
            </button>
            <button onClick={() => handleNavigation("/contactus")} className={linkClasses("/contactus")}>
              {t("contactUs")}
            </button> */}
            {user && (
              <>
                <Link to="/Profile" className={linkClasses("/Profile")}>
                  {t("profile")}
                </Link>
                <Link to="/BookingHistory" className={linkClasses("/BookingHistory")}>
                  {t("bookingHistory")}
                </Link>
                <Link to="/Help" className={linkClasses("/Help")}>
                  <IoMdHelpCircleOutline style={{fontSize:"20px"}} />
                </Link>
              </>
            )}
          </nav>

          <div className="hidden md:flex gap-5 items-center">
            {user ? (
              <div className="relative z-50">
                <div
                  className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-blue-200 rounded-full hover:border-spacing-1"
                  onClick={toggleDropdown}
                >
                  <a className="font-medium text-gray-600">
                    {user?.firstname?.charAt(0)?.toUpperCase()}
                    {user?.lastname?.charAt(0)?.toUpperCase()}
                  </a>
                </div>
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 bg-white rounded-md shadow-xl z-1000">
                    <div className="z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                      <div className="px-4 py-3 text-sm text-gray-900">
                        <div>
                          {user?.firstname} {user?.lastname}
                        </div>
                        <div className="font-medium truncate">{user?.mobile}</div>
                      </div>
        {  user && (window.consumerapp === undefined || window.consumerapp === null) ?(  <div className="py-1">
                        <a
                          href="#"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={handleLogout}
                        >
                          {t("logout")}
                        </a>
                      </div>):null}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <button
                className="bg-pink-700 text-white rounded-md px-4 py-2 hover:bg-pink-600 transition-colors duration-200 ease-in-out"
                onClick={() => navigate("/Login")}
              >
                {t("login")}
              </button>
            )}
          </div>

          {/* Language Selector */}
          <div className="relative inline-block text-left z-50">
          <button
            onClick={toggleLanguageDropdown}
            className="flex items-center px-2 py-1 rounded-full bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
          >
            <MdOutlineLanguage className="text-white w-4 h-4 mr-2" />
            <span className="text-white text-xs md:text-sm font-medium">
              {i18n.language === 'en' ? 'English' : 'አማርኛ'}
            </span>
            <IoIosArrowDown className="text-white w-4 h-4 mr-2" />

          </button>

        {languageDropdownOpen && (
          <div className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <button 
                onClick={() => {
                  i18n.changeLanguage('en');
                  setLanguageDropdownOpen(false);
                }} 
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" 
                role="menuitem"
              >
                English
              </button>
              <button 
                onClick={() => {
                  i18n.changeLanguage('am');
                  setLanguageDropdownOpen(false);
                }} 
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" 
                role="menuitem"
              >
                አማርኛ
              </button>
            </div>
          </div>
        )}
      </div>
        </div>
        <div className="md:hidden flex items-center">
          <button onClick={toggleMenu} className="text-2xl text-primary">
            {menuOpen ? <FaTimes className="text-primary w-8 h-8"   /> : <BiMenuAltLeft className="text-primary ml-2 w-8 h-8" />}
          </button>
        </div>
      </div>
      {/* Mobile Drawer Menu */}
      {menuOpen && (
        <div className="fixed inset-0 z-50 flex">
          {/* Drawer */}
          <div className="bg-primary shadow-md w-64 p-4 flex flex-col space-y-4">
            <img
              loading="lazy"
              src="./qetero_logo_text.svg"
              className="w-24 h-10 mb-4"
              alt="DARS Logo"
            />
            <button
              className= {linkClassesMobile("/")}
              onClick={() => handleNavigation("/")}

            >
              {t("home")}
            </button>
            {/* <button
              className="text-left w-full text-white text-base hover:text-black transition-colors duration-200 ease-in-out"
              onClick={() => handleNavigation("/aboutus")}
            >
              {t("aboutUs")}
            </button>
            <button
              className="text-left text-white w-full text-base hover:text-black transition-colors duration-200 ease-in-out"
              onClick={() => handleNavigation("/contactus")}
            >
              {t("contactUs")}
            </button> */}
            {user ? (
              <>
                <button
              className= {linkClassesMobile("/Profile")}
              onClick={() => handleNavigation("/Profile")}
                >
                  {t("profile")}
                </button>
                <button
              className= {linkClassesMobile("/BookingHistory")}
              onClick={() => handleNavigation("/BookingHistory")}
                >
                  {t("bookingHistory")}
                </button>
                {/* add logout button */}
            { user && (window.consumerapp === undefined || window.consumerapp === null) ? (    <button
                  className="flex justify-start bg-primary rounded-md px-4 py-2 text-xl font-bold text-white my-2"
                  onClick={handleLogout}
                >
                  {t("logout")}
                </button>)
                :null}
              </>
            ) : (
              <button
                className="text-left w-full text-white text-base hover:text-black transition-colors duration-200 ease-in-out"
                onClick={() => handleNavigation("/login")}
              >
                {t("login")}
              </button>
            )}
          </div>
         {/* Add Close Icon on the top left */}

            <div className="absolute top-4 z-50 right-4 cursor-pointer text-white text-2xl" onClick={() => setMenuOpen(false)}>
              <CgClose className="w-8 h-8 text-secondary"/>
            </div>

          {/* Close Drawer when clicking outside */}
          <div
            className="flex-1 backdrop-blur-sm "
            onClick={() => setMenuOpen(false)}
          />
        </div>
      )}
    </header>
  );
}

export default Header;