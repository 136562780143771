import React, { useContext, useState } from 'react';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { AuthContext } from '../../contexts/authContext';
import { FaStar, FaStarAndCrescent, FaStarHalf, FaStarHalfStroke } from 'react-icons/fa6';
import { IoStarOutline } from 'react-icons/io5';

const ReviewModal = ({ lastCheckedOutAppointment }) => {
    console.log("inside review modal",lastCheckedOutAppointment)
    const { t } = useTranslation();
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const [isOpen, setIsOpen] = useState(true); // State to control modal visibility
    const {token} = useContext(AuthContext)
    const [errorMessage, setErrorMessage] = useState(''); // State for error message


    const createReviewMutation = useMutation({
        mutationKey: ['createReview'],
        mutationFn: async () => {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/reviews`, {
                appointment_id: lastCheckedOutAppointment?.lastAppointment?.id,
                rating: rating,
                comments: comment
            },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
        );
            return response.data;
        },
        onSuccess: () => {
            onClose(); // Close the modal after successful submission
        },
        onError: (error) => {
            console.error("Error submitting Review:", error);
            // Handle error, e.g., display an error message
        }
    });

    const handleSubmit = () => {
        if (rating === 0) {
            setErrorMessage(t('Please provide a rating')); // Set error message

            return;
        }

        if ((rating === 1 || rating === 2) && !comment) {  // Check for low rating and empty comment
            setErrorMessage(t('Comment is required')); // Set error message
            return;
        }
        setErrorMessage('') // Clear errors if rating increases.
        createReviewMutation.mutate();
    };

    const onClose = () => {
        setIsOpen(false); // Close the modal
    };
    console.log("update review modal",isOpen,lastCheckedOutAppointment)

    if (!isOpen || lastCheckedOutAppointment?.isReviewed) return null;

    return (
        <div className="fixed overflow-hidden inset-0 z-50 flex items-center justify-center bg-indigo-900 bg-opacity-50 backdrop-blur-sm">
            <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
                <div className="mb-4">
                    <p className="text-gray-600 text-sm">
                        {t('appointment_date')}: {new Date(lastCheckedOutAppointment?.lastAppointment?.updated_at)?.toLocaleDateString()}
                    </p>
                </div>

                <h3 className="text-xl font-semibold mb-2">{t("rate_your_experience")}</h3>

                <div className='flex flex-col' >
                <div className="flex justify-center gap-4 mb-2">
                    {[1, 2, 3, 4, 5].map((star) => (
                        <button className='h-12 w-12 ' key={star} onClick={() => setRating(star)}>
                            {star <= rating ? (
                                <FaStar  className='text-yellow-500 h-10 w-10' />
                            ) : (
                                <IoStarOutline  className=' h-10 w-10 '/>
                            )}
                        </button>

                    ))}
                </div>
                <div className='flex justify-around mb-2' ><span>{t('very bad')}</span><span>{t('very good')}</span></div>
                </div>
                <textarea
                    className="w-full border border-gray-300 rounded-lg p-2 mb-2"
                    placeholder={t('leave_a_comment')}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    rows={3}
                />
                {errorMessage && <p className="text-red-500 text-sm mb-2">{errorMessage}</p>} {/* Display error message */}
                <button
                    onClick={handleSubmit}
                    disabled={createReviewMutation.isLoading} // Disable button during submission
                    className={`bg-blue-500 text-white px-4 py-2 rounded-lg ${createReviewMutation.isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                    {createReviewMutation.isPending ? t('submitting') : t('submit')}
                </button>
            </div>
        </div>
    );
};

export default ReviewModal;